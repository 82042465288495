import React, { useContext, useEffect } from 'react'
import { graphql } from 'gatsby'
import Seo from 'src/components/Seo'
import PageLayout from 'src/components/PageLayout'
import { Typography } from '@local/shared/components/System'
import Box from '@local/shared/components/System/Box'
import BlogItemWrapper from 'src/components/BlogItemWrapper'
import YouTubeVideo from 'src/components/YouTubeVideo'
import DictionaryDefinition from 'src/components/DictionaryDefinition'
import Table from 'src/components/Table'
import Heading from 'src/components/Heading'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@mdx-js/react'
import GithubSlugger from 'github-slugger'
import Hr from 'src/components/Hr'
import styled, { ThemeContext } from 'styled-components'
import PrevNextButtons from 'src/components/PrevNextButtons'
import Img from 'gatsby-image'
import { Hem } from 'src/components/Em'
import Equation from 'src/components/Guide/Equation'
import Image from 'src/components/Guide/Image'
import Blockquote from 'src/components/Guide/Blockquote'
import Reference from 'src/components/Guide/Reference'
import FastingSchedule from 'src/components/Programme/FastingSchedule'
import MainLeadCapture from 'src/components/LeadCapture/MainLeadCapture'
import SavvasProfilePic from 'src/components/SavvasProfilePic'
import InputGroup from 'src/components/InputGroup'
import { MainCta } from 'src/components/Button'
import LeadCapture from 'src/components/LeadCapture/LeadCapture'
import Countdown from 'src/components/Countdown'

const slugger = new GithubSlugger()

const makeHeadingComponent = variant => ({ children }) => (
  <Heading
    slugger={slugger}
    component={({ children }) => (
      <Typography variant={variant}>{children}</Typography>
    )}
  >
    {children}
  </Heading>
)

const H2 = makeHeadingComponent('h2')

const components = {
  Equation,
  BlogItemWrapper,
  YouTubeVideo: props => (
    <Box maxWidth={800} mx={'auto'} px={'1em'}>
      <YouTubeVideo {...props} />
    </Box>
  ),
  DictionaryDefinition,
  FastingSchedule,
  Typography,
  blockquote: Blockquote,
  h1: makeHeadingComponent('h3'),
  h2: makeHeadingComponent('h4'),
  h3: makeHeadingComponent('h5'),
  h4: makeHeadingComponent('h6'),
  em: Hem,
  hr: Hr,
  p: ({ children }) => (
    <Typography as={'p'} variant={'body1'}>
      {children}
    </Typography>
  ),
  table: Table,
  li: ({ children }) => (
    <Typography as={'li'} variant={'body1'} mb={'0'}>
      <span className={'li-wrapper'}>{children}</span>
    </Typography>
  ),
}

const GuideStyled = styled(Box)`
  strong {
    font-weight: 700;
  }

  & > p,
  ol,
  ul {
    margin-bottom: 1em;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1em;
    padding-right: 1em;
  }

  ol,
  li {
    font-weight: bold;
  }

  li > .li-wrapper {
    font-weight: normal;
  }

  & > p:last-child {
    margin-bottom: 1em;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 1.5em auto 0.5em;
    max-width: 800px;
    padding-left: 1em;
    padding-right: 1em;
  }

  ol,
  ul {
    margin-top: 0.5em;
    padding-left: 2.5em;
  }

  & > p:last-of-type {
    margin-bottom: 0;
  }
`

const CountdownFreeAppointmentRemaining = () => (
  <Countdown
    text={
      <>
        Only <strong>3 free consultations</strong> remaining over the next 7
        days.
      </>
    }
    len={10}
    countdown={3}
  />
)

const GuidePage = ({ location, data, pageContext }) => {
  console.log('pageContext', pageContext)
  const page = data.mdx
  const referenceCounter = { count: 0 }

  const images = {}
  if (page.frontmatter.images) {
    page.frontmatter.images.forEach(image => {
      const { name, caption, path, credits } = image
      const { childImageSharp, publicURL } = path
      console.log('image', image)
      if (childImageSharp) {
        const { fluid } = childImageSharp
        images[name] = props => (
          <Image caption={caption} credits={credits} publicUrl={publicURL}>
            <Img fluid={fluid} {...props} />
          </Image>
        )
      } else {
        images[name] = props => (
          <Image caption={caption} credits={credits} publicUrl={publicURL}>
            <Box
              as={'img'}
              display={'block'}
              mx={'auto'}
              width={1}
              src={publicURL}
              {...props}
            />
          </Image>
        )
      }
    })
  }

  const references = {}
  if (page.frontmatter.references) {
    page.frontmatter.references.forEach(ref => {
      references[ref.name] = () => (
        <Reference {...ref} referenceCounter={referenceCounter} />
      )
    })
  }

  return (
    <PageLayout type={'full'}>
      <Seo
        title={pageContext.config.title}
        description={pageContext.config.description}
        shortTeaser={pageContext.config.shortTeaser}
        article={{
          createdDatetime: pageContext.config.createdDatetime,
          modifiedDatetime: pageContext.config.modifiedDatetime,
        }}
        locationPathname={location.pathname}
      />
      <Box py={'4em'} bgcolor={'blue.light1'}>
        <Box maxWidth={800} mx={'auto'} px={'2rem'}>
          <Typography variant={'subtitle'}>THE COMPLETE GUIDE</Typography>
          <Typography variant={'h1'} mb={'0.5em'}>
            {pageContext.config.title}
          </Typography>
          <Typography variant={'subtitle'} mb={'0.5em'}>
            {pageContext.config.subtitle}
          </Typography>
        </Box>
      </Box>
      <Box maxWidth={800} mx={'auto'} my={'2em'} px={'2rem'}>
        <Typography as={'p'} variant={'h6'} mb={'0.5rem'}>
          CHAPTER {page.fields.page}
        </Typography>
        <H2>{page.frontmatter.pageTitle}</H2>
      </Box>
      <GuideStyled mx={'auto'} mt={'2em'} mb={'1em'}>
        <MDXProvider components={components}>
          <MDXRenderer images={images} references={references}>
            {page.body}
          </MDXRenderer>
        </MDXProvider>
        <Box maxWidth={800} mx={'auto'} px={'1em'}>
          <PrevNextButtons
            previous={pageContext.previous}
            next={pageContext.next}
          />
        </Box>
      </GuideStyled>
      <LeadCapture
        title={'Book your free 30-minute phone consultation!'}
        subtitle={`Finally: Lose weight after years of trying!`}
        countdown={<CountdownFreeAppointmentRemaining />}
        bullets={
          <>
            <ul>
              <Typography variant={'body1'} mb={'0.5em'} as={'li'}>
                Learn exactly <Hem>why you can't lose weight</Hem>!
              </Typography>
              <Typography variant={'body1'} mb={'0.5em'} as={'li'}>
                Learn simple strategies to <Hem>reset your metabolism</Hem>.
              </Typography>
              <Typography variant={'body1'} mb={'0.5em'} as={'li'}>
                <Hem>Speak to an professional</Hem> about how fasting can work
                for me.
              </Typography>
              <Typography variant={'body1'} mb={'0.5em'} as={'li'}>
                Learn how to{' '}
                <Hem>finally get the body and the life you want</Hem> after
                years of trying!
              </Typography>
            </ul>
            <Typography variant={'body1'} mb={'0.5em'} ml={'1em'}>
              ...and so much more!
            </Typography>
          </>
        }
        rhs={
          <Box width={{ md: '100%' }}>
            <SavvasProfilePic />
            <Typography
              variant={'h6'}
              as={'p'}
              textAlign={'center'}
              mb={'0.5em'}
            >
              Coach Savvas Nicholas
            </Typography>
            <Typography variant={'subnote'} as={'p'} textAlign={'center'}>
              Weight Loss Expert
            </Typography>
          </Box>
        }
        formAction={'/thank-you/free-consultation'}
        formName={'lead-capture-free-consultation'}
        formFields={
          <>
            <InputGroup
              name={'full-name'}
              id={'full-name'}
              labelText={'Full Name'}
              placeholder={'Full Name'}
              type={'text'}
            />
            <InputGroup
              name={'email'}
              id={'email'}
              labelText={'Email'}
              placeholder={'Email'}
              type={'email'}
            />
            <MainCta
              width={1}
              trackingName={'submit'}
              type={'submit'}
              mainContent={'Book Free 30-Minute Phone Consultation'}
              subtitleText={'Yes, I want free expert advice'}
            />
            <Typography variant={'subnote'} textAlign={'center'} mt={'0.5em'}>
              We will email to arrange a time that suits you
            </Typography>
          </>
        }
      />
    </PageLayout>
  )
}

export default GuidePage

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      fields {
        slug
        page
      }
      frontmatter {
        pageTitle
        created(formatString: "MMMM DD, YYYY")
        createdDatetime: created
        modifiedDatetime: modified
        references {
          name
          url
        }
        images {
          name
          caption
          credits {
            text
            link
          }
          path {
            publicURL
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
