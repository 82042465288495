import React from 'react'

const Reference = ({ url, referenceCounter }) => {
  referenceCounter.count++
  return (
    <a href={url} target={'_blank'}>
      [{referenceCounter.count}]
    </a>
  )
}

export default Reference
