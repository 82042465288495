import { graphql, useStaticQuery } from 'gatsby'
import Typography from '@local/shared/components/System/Typography'
import Box from '@local/shared/components/System/Box'
import Img from 'gatsby-image/withIEPolyfill'
import InputGroup from 'src/components/InputGroup'
import { MainCta2 } from 'src/components/Button'
import React from 'react'
import LeadCapture from 'src/components/LeadCapture/LeadCapture'

const MainLeadCapture = React.forwardRef((props, ref) => {
  const formRef = ref
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "savvas-profile.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <LeadCapture
      title={
        <>
          Book your <span css={{ textDecoration: 'underline' }}>FREE</span>{' '}
          30-minute phone consultation!
        </>
      }
      bullets={
        <>
          <Typography variant={'h6'} as={'p'} mb={'0.5em'}>
            Struggling to lose weight? Always feeling hungry? Keep losing
            motivation?
          </Typography>
          <Typography variant={'h6'} as={'p'}>
            During the call, we will:
          </Typography>
          <Box as={'ul'} css={{ paddingInlineStart: '24px' }}>
            <Typography variant={'h6'} mb={'0.5em'} as={'li'}>
              <strong>Evaluate your current methods</strong> and weight loss
              goals!
            </Typography>
            <Typography variant={'h6'} mb={'0.5em'} as={'li'}>
              Learn the hidden secrets of weight loss and how to{' '}
              <strong>never feel hungry!</strong>
            </Typography>
            <Typography variant={'h6'} mb={'0.5em'} as={'li'}>
              Learn how to lose weight{' '}
              <strong>without stepping foot in a gym!</strong>
            </Typography>
            <Typography variant={'h6'} mb={'0.5em'} as={'li'}>
              Learn how to <strong>finally stop the yo-yo dieting</strong> and
              look good forever!
            </Typography>
            <Typography variant={'h6'} mb={'0.5em'} as={'li'}>
              Tell you all about{' '}
              <strong>
                the one single way to lose weight as fast as humanly possible!
              </strong>
            </Typography>
          </Box>
        </>
      }
      rhs={
        <Box width={{ md: '100%' }}>
          <Box
            borderRadius={'50%'}
            css={{ overflow: 'hidden' }}
            border={'solid 0.25em'}
            borderColor={'blue.0'}
            mb={'1.25em'}
          >
            <Img fluid={data.file.childImageSharp.fluid} objectFit={'cover'} />
          </Box>
          <Typography variant={'h6'} as={'p'} textAlign={'center'}>
            Coach Savvas Nicholas
          </Typography>
        </Box>
      }
      formName={'lead-capture-consultation'}
      formAction={'/thank-you'}
      formFields={
        <>
          <InputGroup
            name={'full-name'}
            id={'full-name'}
            labelText={'Full Name'}
            placeholder={'Full Name'}
            type={'text'}
            ref={formRef}
          />
          <InputGroup
            name={'email'}
            id={'email'}
            labelText={'Email'}
            placeholder={'Email'}
            type={'email'}
          />
          <MainCta2 width={1} />
          <Typography variant={'subnote'} textAlign={'center'} mt={'0.5em'}>
            We will email to arrange a time that suits you
          </Typography>
        </>
      }
    />
  )
})

export default MainLeadCapture
