import React, { useContext } from 'react'
import Box from '@local/shared/components/System/Box'
import styled, { ThemeContext } from 'styled-components'
import Typography from '@local/shared/components/System/Typography'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

const Root = styled(Box)`
  .gatsby-image-wrapper {
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  }
`

const Image = ({ children, caption, credits, publicUrl, ...props }) => {
  return (
    <Root my={'2em'} maxWidth={800} mx={'auto'} px={'1em'} {...props}>
      {credits && (
        <Typography
          textAlign={'right'}
          color={'text.placeholder'}
          fontFamily={'headerFontFamily'}
          variant={'p'}
          scale={-2}
          my={'1em'}
        >
          Credit:
          {credits.map((c, i) => (
            <React.Fragment key={i}>
              {' '}
              <OutboundLink href={c.link}>{c.text}</OutboundLink>
              {i !== credits.length - 1 && ','}
            </React.Fragment>
          ))}
        </Typography>
      )}
      <OutboundLink href={publicUrl}>{children}</OutboundLink>
      {caption && (
        <Typography
          textAlign={'center'}
          color={'text.placeholder'}
          variant={'p'}
          my={'1em'}
          fontFamily={'headerFontFamily'}
        >
          {caption}
        </Typography>
      )}
    </Root>
  )
}

export default Image
