import React from 'react'
import Button from 'src/components/Button'
import { Link } from '@reach/router'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import Box from '@local/shared/components/System/Box'
import { Typography } from '@local/shared/components/System'

const CONFIG = {
  next: {
    container: {
      textAlign: 'right',
      ml: 'auto',
      bgcolor: 'blue.light1',
    },
    button: {
      props: {
        rightIcon: MdKeyboardArrowRight,
        variant: 'secondary',
      },
      text: 'Next',
    },
  },
  previous: {
    container: {
      textAlign: 'left',
      mr: 'auto',
      bgcolor: 'blue.light2',
    },
    button: {
      props: {
        leftIcon: MdKeyboardArrowLeft,
        variant: 'secondary',
      },
      text: 'Back',
    },
  },
}

const ChapterButton = ({ type, node: { page, slug, title } }) => {
  return (
    <Box p={'1em'} mb={'1em'} {...CONFIG[type].container} borderRadius={5}>
      <Typography variant={'h6'} as={'div'}>
        CHAPTER {page}
      </Typography>
      <Typography variant={'h5'} as={'div'} color={'#222'}>
        {title}
      </Typography>
      <Link to={slug}>
        <Button
          variant={'primary'}
          size={'sm'}
          mt={'0.5em'}
          {...CONFIG[type].button.props}
        >
          {CONFIG[type].button.text}
        </Button>
      </Link>
    </Box>
  )
}

const PrevNextButtons = ({ previous, next }) => {
  console.log('previous, next', previous, next)

  return (
    <Box my={'1em'}>
      {next && <ChapterButton type={'next'} node={next} />}
      {previous && <ChapterButton type={'previous'} node={previous} />}
    </Box>
  )
}

export default PrevNextButtons
