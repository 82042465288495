import React from 'react'
import { Box, Typography } from '@local/shared/components/System'
import styled from 'styled-components'

const Root = styled(Box)`
  .term:last-child {
    margin-right: 0;
  }
`

const Equation = ({ lhs, rhs }) => {
  console.log('rhs', rhs)
  return (
    <Box maxWidth={800} mx={'auto'}>
      <Root
        display={{ vs: 'flex' }}
        alignItems={'center'}
        flexWrap={'wrap'}
        css={{ textTransform: 'uppercase' }}
        my={'1em'}
        ml={{ sm: '2em' }}
      >
        <Typography
          variant={'body1'}
          fontWeight={700}
          as={'div'}
          ml={'0'}
          mr={'0.5em'}
        >
          {lhs}
        </Typography>
        <Typography
          variant={'body1'}
          flex={'0'}
          mr={'0.5em'}
          fontWeight={700}
          as={'div'}
        >
          =
        </Typography>
        <Box display={'flex'} flexWrap={'wrap'} flex={1}>
          {rhs.map((t, i) => (
            <Typography
              variant={'body1'}
              fontWeight={700}
              mr={'0.5em'}
              as={'div'}
              key={i}
              className={'term'}
            >
              {t}
            </Typography>
          ))}
        </Box>
      </Root>
    </Box>
  )
}

// const Equation = ({ children }) => {
//   const equation = katex.renderToString(children)
//   console.log('Equation children', children)
//   return (
//     <Box>
//       <div dangerouslySetInnerHTML={{ __html: equation }} />
//     </Box>
//   )
// }

export default Equation
