import React from 'react'
import { Box } from '@local/shared/components/System'

const Blockquote = ({ children }) => {
  return (
    <Box px={'1em'} width={1} maxWidth={800} mx={'auto'} my={'1em'}>
      <Box
        p={'1em'}
        as={'blockquote'}
        css={{
          marginBlockStart: 0,
          marginBlockEnd: 0,
          marginInlineStart: 0,
          marginInlineEnd: 0,
          '& > p': {
            textAlign: 'center',
          },
        }}
        bgcolor={'blue.light1'}
        width={1}
        borderRadius={5}
      >
        {children}
      </Box>
    </Box>
  )
}

export default Blockquote
