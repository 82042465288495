import React from 'react'
import Box from '@local/shared/components/System/Box'
import wildlifeFlowerIconImg from 'src/images/wildlife-flower-icon.svg'

const Hr = () => (
  <Box
    position={'relative'}
    my={'2em'}
    height={`${1 / 16}em`}
    bgcolor={'text.blockquote'}
  >
    <Box
      mx={'auto'}
      width={1}
      textAlign={'center'}
      position={'relative'}
      top={'-0.5em'}
    >
      <Box bgcolor={'white'} px={'1em'} display={'inline-block'}>
        <Box as={'img'} src={wildlifeFlowerIconImg} height={'1em'} />
      </Box>
    </Box>
  </Box>
)

export default Hr
